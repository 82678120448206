@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";


.c-type-importantNumbers {
    padding: 4rem 0;

    .numbers-container {
        .img-wrapper {
            img {
                width: 100%;
                height: auto;
            }
        }

        .number-item {
            display: flex;
            margin: auto;
            max-width: 27rem;
            padding: 1rem 0;

            .number-img-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 5rem;

                .svg-icon {
                    width: 4.5rem;
                    height: 4.5rem;
                }
            }

            .number-text-wrapper {
                padding: 0 1rem;

                .number {
                    display: block;
                    font-size: $font-size-number * 0.9;
                    font-weight: $font-weight-medium;
                    line-height: 1;
                    color: $secondary;
                }

                .number-title {
                    padding-left: 1.5rem;
                    white-space: nowrap;
                    font-size: $font-size-number-title * 0.7;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .c-type-importantNumbers {
        .numbers-container {
            .number-item {
                .number-text-wrapper {
                    .number {
                        font-size: $font-size-number;
                    }

                    .number-title {
                        font-size: $font-size-number-title;
                    }
                }
            }
        }
    }
}


@include media-breakpoint-up(lg) {
    .c-type-importantNumbers {
        .numbers-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .img-wrapper {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .c-type-importantNumbers {
        .numbers-container {
            .number-item {
                .number-text-wrapper {
                    .number {
                        font-size: $font-size-number-xl;
                    }

                    .number-title {
                        font-size: $font-size-number-title-xl;
                    }
                }
            }
        }
    }
}
